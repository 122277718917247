import { AlertCircle, Mail, Search } from 'lucide-react';
import React, { ChangeEvent, useEffect, useId, useState } from 'react';
import { tv } from 'tailwind-variants';

export const inputWrapperClasses =
  'rounded-input flex flex-row border-slate-200 border p-[1px] shadow-sm focus-within:ring-2 focus-within:ring-brand-200 focus-within:border-brand-400';
export const inputBaseClasses =
  '[&::-webkit-search-cancel-button]:hidden block w-full rounded-input border-0 p-0 text-slate-900 placeholder:text-slate-400 focus-visible:ring-0 focus-visible:outline-none focus-visible:ring-transparent py-1 px-3 text-sm md:text-base';
export const labelBaseClasses = 'block text-sm font-semibold leading-6';

type InputElement = HTMLInputElement | HTMLTextAreaElement;

const textInputClasses = tv({
  slots: {
    layout: 'flex grow flex-col',
    label: labelBaseClasses,
    inputWrapper: inputWrapperClasses,
    input: inputBaseClasses,
    icon: 'pointer-events-none flex items-center justify-center pl-1.5 group-focus:ring-blue-600',
    error: 'mt-1 h-5 text-sm',
  },
  variants: {
    type: {
      textarea: {
        inputWrapper: 'flex grow flex-row',
        input: 'form-textarea',
      },
      text: {
        input: 'form-input',
      },
      email: {},
      password: {},
      url: {},
      number: {},
      search: {},
    },
    variant: {
      default: {
        inputWrapper: inputWrapperClasses,
        input: inputBaseClasses,
      },
      naked: {
        inputWrapper:
          'rounded-input flex flex-row border-transparent shadow-none h-8 hover:bg-slate-100 focus:bg-white',
        input:
          'border-transparent shadow-none text-inherit bg-transparent px-2 truncate',
      },
    },

    // Booleans
    inline: {
      true: {
        layout: 'flex flex-row gap-3',
        label: 'pt-3',
        inputWrapper: '',
        input: '',
      },
    },
    error: {
      true: {
        label: 'text-red-500',
        error: 'text-red-600',
        icon: 'text-red-600',
        input: 'focus:ring-red-500',
        labelWrapper: 'ring-red-600 focus-within:ring-red-600',
      },
      false: {
        label: 'text-slate-600',
        error: 'text-slate-500',
        icon: 'text-slate-400',
        input: 'focus:ring-indigo-600',
      },
    },

    label: {
      true: {
        inputWrapper: 'mt-1',
      },
    },
  },
});

export const TextInput: React.FC<{
  id?: string;
  label?: string | React.ReactNode;
  placeholder?: string;
  type?:
    | 'text'
    | 'email'
    | 'password'
    | 'textarea'
    | 'url'
    | 'number'
    | 'search';
  variant?: 'naked' | 'default';
  className?: string;
  error?: boolean;
  disabled?: boolean;
  errorLabel?: string | React.ReactNode;
  helperText?: string | React.ReactNode;
  inline?: boolean;
  value?: string | number;
  required?: boolean;
  onKeyDown?: React.KeyboardEventHandler<InputElement>;
  onBlur?: (value: string) => void;
  onChange?: (value: string) => void;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  textAreaProps?: React.TextareaHTMLAttributes<HTMLTextAreaElement>;
  autoFocus?: boolean;
}> = ({
  id,
  disabled,
  label,
  placeholder,
  type = 'text',
  error = false,
  errorLabel,
  helperText,
  inline = false,
  value,
  required = false,
  onKeyDown,
  onBlur,
  onChange,
  inputProps = {},
  textAreaProps = {},
  autoFocus,
  className,
  variant = 'default',
}) => {
  const [displayValue, setDisplayValue] = useState(value);
  const textFieldId = useId();

  useEffect(() => {
    setDisplayValue(value);
  }, [value]);

  const onTextFieldChange = (e: ChangeEvent<InputElement>) => {
    setDisplayValue(e.target.value);
    onChange?.(e.target.value);
  };
  const onTextFieldBlur = () => onBlur?.(String(displayValue));
  const classes = textInputClasses({
    variant,
    inline,
    error,
    type,
    label: Boolean(label),
  });

  const defaultInput = (
    <input
      id={id}
      disabled={disabled}
      type={type}
      name={id || textFieldId}
      value={displayValue}
      onChange={onTextFieldChange}
      required={required}
      placeholder={placeholder?.toString()}
      aria-describedby={`${id || textFieldId}-description`}
      autoFocus={autoFocus}
      onBlur={onTextFieldBlur}
      onKeyDown={onKeyDown}
      {...inputProps}
      className={classes.input({ className: inputProps.className })}
    />
  );

  const textAreaInput = (
    <textarea
      id={id}
      disabled={disabled}
      rows={4}
      name={id ?? textFieldId}
      required={required}
      onChange={onTextFieldChange}
      value={displayValue}
      placeholder={placeholder?.toString()}
      aria-describedby={`${textFieldId}-description`}
      {...textAreaProps}
      autoFocus={autoFocus}
      onBlur={onTextFieldBlur}
      onKeyDown={onKeyDown}
      className={classes.input({ className: textAreaProps.className })}
    />
  );

  return (
    <div className={classes.layout({ className })}>
      <label htmlFor={id ?? textFieldId} className={classes.label()}>
        {label}
      </label>
      <div className="flex grow flex-col">
        <div className={classes.inputWrapper()}>
          {type === 'email' || type === 'search' ? (
            <div className={classes.icon()}>
              {type === 'search' ? (
                <Search size="1.25rem" aria-hidden="true" />
              ) : (
                <Mail size="1.25rem" aria-hidden="true" />
              )}
            </div>
          ) : null}
          {type === 'textarea' ? textAreaInput : defaultInput}
          {error && (
            <div className="pointer-events-none flex items-center justify-center pr-1.5 group-focus:ring-blue-600">
              <AlertCircle className="size-5 text-red-600" aria-hidden="true" />
            </div>
          )}
        </div>
        {helperText ||
          (errorLabel && (
            <p className={classes.error()}>{error ? errorLabel : helperText}</p>
          ))}
      </div>
    </div>
  );
};
